<template>
  <div>
    <component-menu></component-menu>
    <div class="container">
      <div class="row">
        <div class="col-lg-12 mb-2">
          <h2>{{$t("lang.application.json_tools.name")}}</h2>
          <p class="fw-light">{{$t("lang.application.json_tools.detailed_description")}}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 mb-2">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <label for="process-target" class="col-lg-12 form-label text-start">{{$t("lang.label.input")}}</label>
                <div class="col-lg-12">
                  <textarea class="form-control mb-2" id="process-target" rows="3" v-model="createForm.target" :placeholder="`${$t('lang.placeholder.json')}`"></textarea>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12 text-end">
                  <button type="button" class="btn btn-primary btn-sm mb-2" data-dismiss="modal" @click="executeProcess($event, 'reshape')">{{$t("lang.label.beautify")}}</button>&nbsp;
                  <button type="button" class="btn btn-primary btn-sm mb-2" data-dismiss="modal" @click="executeProcess($event, 'minify')">{{$t("lang.label.minify")}}</button>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12 text-end">
                  <button type="button" class="btn btn-primary btn-sm mb-2" data-dismiss="modal" @click="executeProcess($event, 'yaml')">{{$t("lang.label.to_yaml")}}</button>&nbsp;
                  <button type="button" class="btn btn-primary btn-sm mb-2" data-dismiss="modal" @click="executeProcess($event, 'toml')">{{$t("lang.label.to_toml")}}</button>&nbsp;
                  <button type="button" class="btn btn-primary btn-sm mb-2" data-dismiss="modal" @click="executeProcess($event, 'xml')">{{$t("lang.label.to_xml")}}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 mb-2" id="process-result">
          <div class="card" v-if="processResults.loaded === true">
            <div class="card-body">
              <div class="row">
                <div class="col-lg-12">
                  <label for="process-result-text" class="col-lg-12 form-label text-start">{{$t("lang.label.output")}}</label>
                  <div class="col-lg-12">
                    <textarea class="form-control mb-2" id="process-result-text" rows="3" v-model="resultForm.target" readonly></textarea>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12 text-end">
                  <div class="form-inline" v-show="resultForm.process != 'minify' && resultForm.type != 'toml'">
                    <select class="form-select mb-2" style="width:240px !important; display:inline-block !important;" v-model="resultForm.indent">
                      <option value="0">Indent 0 Space</option>
                      <option value="1">Indent 1 Space</option>
                      <option value="2">Indent 2 Space</option>
                      <option value="3">Indent 3 Space</option>
                      <option value="4">Indent 4 Space</option>
                      <option value="5">Indent 5 Space</option>
                      <option value="6">Indent 6 Space</option>
                      <option value="7">Indent 7 Space</option>
                      <option value="8">Indent 8 Space</option>
                    </select>
                    &nbsp;
                    <button type="button" class="btn btn-info btn-sm mb-2" @click="executeProcessAfter($event, 'reshape')">{{$t("lang.label.beautify")}}</button>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12 text-end">
                  <button type="button" class="btn btn-info btn-sm mb-2" @click="executeProcessAfter($event, 'minify')" v-show="resultForm.type != 'toml'">{{$t("lang.label.minify")}}</button>&nbsp;
                  <button type="button" class="btn btn-info btn-sm mb-2" @click="executeProcessAfter($event, 'copy')">{{$t("lang.label.copy")}}</button>
                </div>
              </div>
            </div>
          </div>
          <div class="card" v-else>
            <div class="card-body">
              <div class="alert alert-info" role="alert">{{$t("lang.alert.result")}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <component-footer></component-footer>
  </div>
</template>

<script>
  import Vue from 'vue';
  import $ from 'jquery';
  import yaml from 'js-yaml';
  import toml from '@iarna/toml';
  import xml from 'xml2js';
  //import csvDecoder from 'csv-parse/lib/sync';
  //import csvEncoder from 'csv-stringify/lib/sync';
  export default {
    metaInfo : {
      title: `${window.application.title} ${window.application.lang.application.json_tools.name}`,
      meta: [
        {name: 'description', conent: `${window.application.lang.application.json_tools.meta_description}`}
      ]
    },
    head: {
      link: [
        {rel: 'alternate', hreflang: 'en', href: 'https://ezeful.app/en/jsontools'},
        {rel: 'alternate', hreflang: 'es', href: 'https://ezeful.app/es/jsontools'},
        {rel: 'alternate', hreflang: 'hi', href: 'https://ezeful.app/hi/jsontools'},
        {rel: 'alternate', hreflang: 'ja', href: 'https://ezeful.app/ja/jsontools'},
        {rel: 'alternate', hreflang: 'zh-cn', href: 'https://ezeful.app/zh_cn/jsontools'},
        {rel: 'alternate', hreflang: 'zh-tw', href: 'https://ezeful.app/zh_tw/jsontools'},
      ],
      //script: [
      //  { src: window.application.adsense_script, async: true, crossorigin:"anonymous"},
      //],
    },
    data () {
      return {
        lang: window.application.lang,
        createForm: {
          type: "json",
          indent: 2,
          target: ""
        },
        resultForm: {
          type: "",
          process: "",
          indent: 2,
          target: ""
        },
        processResults: {
          loaded: false,
          length: 0
        }
      }
    },
    created() {
      Vue.component('component-menu', require('../components/Menu.vue').default);
      Vue.component('component-footer', require('../components/Footer.vue').default);
    },
    mounted() {
      let self = this
      self.showSpecificBlocks();
    },
    updated() {
      //let self = this;
    },
    methods: {
      showSpecificBlocks() {},
      executeProcess(event, process) {
        let self = this;
        let obj = window.button.loading(event);
        window.display.loading(self.processResults);

        let resultString = "";
        self.resultForm.target = "";
        self.resultForm.indent = self.createForm.indent;
        self.resultForm.process = process;

        try {
          if (process == 'minify') {
            self.resultForm.type = self.createForm.type;
            resultString = self.minify(self.createForm.target, self.createForm.type);
          } else if (process == 'reshape') {
            self.resultForm.type = self.createForm.type;
            resultString = self.reshape(self.createForm.target, self.createForm.type, self.createForm.indent);
          } else {
            self.resultForm.type = process;
            resultString = self.convert(self.createForm.target, process, self.createForm.indent);
          }
          self.resultForm.target = resultString;
        } catch(e) {
          self.resultForm.target = self.lang.validation.unknown;
        }

        window.display.reset(self.processResults);
        window.button.reset(obj);
        window.location.element('#process-result');
      },
      executeProcessAfter(event, process) {
        let self = this;
        let obj = window.button.loading(event);
        window.display.loading(self.processResults);

        let resultString = "";

        try {
          if (process == 'minify') {
            resultString = self.minify(self.resultForm.target, self.resultForm.type);
            self.resultForm.target = resultString;
          } else if (process == 'reshape') {
            resultString = self.reshape(self.resultForm.target, self.resultForm.type, self.resultForm.indent);
            self.resultForm.target = resultString;
          } else if (process == 'copy') {
            self.copy();
          }
        } catch(e) {
          self.resultForm.target = self.lang.validation.unknown;
        }

        window.display.reset(self.processResults);
        window.button.reset(obj);
        //window.location.element('#process-result');
      },
      copy() {
        let self = this;
        let textarea = $("#process-result-text");
        textarea.select();
        document.execCommand("copy");
        window.getSelection().removeAllRanges();
        alert(self.lang.alert.copied);
      },
      minify(str, type) {
        let self = this;
        let resultString = "";
        let parsedObject = {};

        if (type == 'json') {
          parsedObject = JSON.parse(str);
          resultString = JSON.stringify(parsedObject);
        } else if (type == 'yaml') {
          parsedObject = yaml.safeLoad(str);
          resultString = yaml.dump(parsedObject, {flowLevel: 1});
        } else if (type == 'toml') {
          parsedObject = toml.parse(str);
          resultString = toml.stringify(parsedObject);
        } else if (type == 'xml') {
          xml.parseString(str, function (err, resultString) {
            parsedObject = resultString;
          });
          let builder = new xml.Builder({renderOpts: { pretty: true, indent: '', newline: ''}});
          resultString = builder.buildObject(parsedObject);
        }

        //Check
        self.check(resultString, type);

        return resultString;
      },
      reshape(str, type, indent) {
        let self = this;
        let resultString = "";
        let parsedObject = {};
        
        if (type == 'json') {
          parsedObject = JSON.parse(str);
          resultString = JSON.stringify(parsedObject, null, parseInt(indent, 10));
        } else if (type == 'yaml') {
          parsedObject = yaml.safeLoad(str);
          resultString = yaml.dump(parsedObject, {indent: parseInt(indent, 10)});
        } else if (type == 'toml') {
          parsedObject = toml.parse(str);
          resultString = toml.stringify(parsedObject);
        } else if (type == 'xml') {
          xml.parseString(str, function (err, resultString) {
            parsedObject = resultString;
          });
          let indentString = '';
          for (let i = 0; i<parseInt(indent, 10); i++) {
            indentString += ' ';
          }
          let builder = new xml.Builder({renderOpts: { pretty: true, indent: indentString, newline: '\n'}});
          resultString = builder.buildObject(parsedObject);
        }

        //Check
        self.check(resultString, type);

        return resultString;
      },
      convert(str, type, indent) {
        let self = this;
        let resultString = '';
        let parsedObject = JSON.parse(str);
        
        if (type == 'json') {
          resultString = JSON.stringify(parsedObject, null, parseInt(indent, 10));
        } else if (type == 'yaml') {
          resultString = yaml.dump(parsedObject, {indent: parseInt(indent, 10)});
        } else if (type == 'toml') {
          resultString = toml.stringify(parsedObject);
        } else if (type == 'xml') {
          let indentString = '';
          for (let i = 0; i<parseInt(indent, 10); i++) {
            indentString += ' ';
          }
          let builder = new xml.Builder({renderOpts: { pretty: true, indent: indentString, newline: '\n'}});
          resultString = builder.buildObject(parsedObject);
        }

        //Check
        self.check(resultString, type);

        return resultString;
      },
      check(str, type) {
        let parsedObject = {};
        if (type == 'json') {
          parsedObject = JSON.parse(str);
        } else if (type == 'yaml') {
          parsedObject = yaml.safeLoad(str);
        } else if (type == 'toml') {
          parsedObject = toml.parse(str);
        } else if (type == 'xml') {
          xml.parseString(str, function (err, resultObject) {
            parsedObject = resultObject;
          });
        }
        toml.stringify(parsedObject);
      },
    }
  }
</script>

<style>
  
  /** Extra Small Screen (col-xs) ********************************************************/
  @media (max-width: 767px) {

  }

  /** Small Screen (col-sm) **************************************************************/
  @media (min-width: 768px) {

  }

  /** Medium Screen (col-md) *************************************************************/
  @media (min-width: 992px) {

  }
  /** Large Screen (col-lg) **************************************************************/
  @media (min-width: 1200px) { /*元は1200px*/

  }

</style>
